html,
body,
#root {
  height: 100%;
  font-size: 16px;
}
html * {
  box-sizing: border-box;
}
#root {
  display: flex;
  flex-direction: column;
}

#app-logo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  img {
    display: block;
    max-width: 50px;
  }
  h1 {
    font-size: 1.25rem;
    line-height: 1.1;
    margin: 10px auto;
    text-align: center;
  }
}

.iframe-tabs {
  list-style: none;
  width: 100%;
  display: flex;
  margin: 0;
  padding: 0;
  border-bottom: 1px solid #ccc;
  overflow-x: auto;
  li {
    text-align: center;
    display: block;
    padding: 10px 15px;
    user-select: none;
    cursor: pointer;
    white-space: nowrap;
    &.active, &:hover, &:active, &:focus {
      background-color: #209CEE;
      color: #fff;
    }
  }
}

.filters {
  overflow: auto;
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}
::-webkit-scrollbar-track {
  border-radius: 10px;
  background: rgba(0,0,0,0.1);
}
::-webkit-scrollbar-thumb{
  border-radius: 10px;
  background: rgba(0,0,0,0.2);
}
::-webkit-scrollbar-thumb:hover{
  background: rgba(0,0,0,0.4);
}
::-webkit-scrollbar-thumb:active{
  background: rgba(0,0,0,.9);
}

.ReactModal__Overlay.ReactModal__Overlay--after-open {
  z-index: 99999;
}